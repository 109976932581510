import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

import translator from '$utils/translator'

import imgLogoDesktop from '$assets/logo_vertical_white.svg'
import imgLogoMobile from './logo_mobile.svg'

import styles from './Content.module.scss'

const Content = ({ image }) => {
  const { telComputer, telHumanFull, email, address } = translator()

  return (
    <>
      <Image fluid={image} className={styles.mobileImage} alt="Contact" />

      <div className={styles.content}>
        <div className="wrapper-m">
          <div className={styles.logo}>
            <div className={styles.logoDesktop}>
              <img src={imgLogoDesktop} alt="logo" />
            </div>

            <div className={styles.logoMobile}>
              <img src={imgLogoMobile} alt="logo" />
            </div>
          </div>

          <div className={styles.text}>
            <h2 className="t-heading-xs">Willem Fleskens</h2>
            <a href={`tel:${telComputer}`} className={styles.telLink}>
              {telHumanFull}
            </a>
            <br />
            <br />
            <span>{address}</span>
            <br />
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.emailLink}
            >
              {email}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

Content.propTypes = {
  image: PropTypes.any.isRequired,
}

export default Content
