import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import Meta from '$components/common/Meta'
import Content from '$components/pageContact/Content'

const Contact = ({ data: { image } }) => (
  <BackgroundImage
    critical
    className="contentBackgroundImage"
    fluid={image.childImageSharp.fluid}
    style={{ height: '100%' }}
  >
    <Meta title="Contact" />

    <Content image={image.childImageSharp.fluid} />
  </BackgroundImage>
)

export default Contact

export const query = graphql`
  query Contact {
    image: file(relativePath: { eq: "contact_bg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
